import React, {useEffect, useRef, useState} from "react";
import {DayPilot, DayPilotScheduler} from "daypilot-pro-react";
import {Container} from "aurelia-dependency-injection";
import {I18N} from "aurelia-i18n";
import {Client} from "../../api/client";
import {FlashService} from "../../flash/flash-service";

export const VacancyDisplay = ({
                                   resources,
                                   events,
                                   columns,
                                   compactView,
                                   fromDate,
                                   days,
                                   showValue,
                                   compareValue,
                                   locale
                               }) => {

    let timeline = [];
    let scale = 'Day';

    const counterPart = {
        virtualBookable: 'virtualCabineCount',
        realBookable: 'realCabineCount',
        blocked: 'realCabineCount',
        blockedSubContingent: 'realCabineCount',
        booked: 'realCabineCount',
        bookedSingle: 'booked',
        bookedSubContingent: 'booked',
    };

    const i18n = Container.instance.get(I18N);
    const flash = Container.instance.get(FlashService);
    const client = Container.instance.get(Client);

    const colors = {
        neutral: ['#a6dbf4', '#46b4e7'],
        free: ['#ccf8c9', '#1f6e19'],
        warning: ['#f1f2b0', '#bcbe1f'],
        full: ['#fff', '#fff'],
    };

    if (compactView) {
        for (let column of columns) {

            const day = {};
            day.start = new DayPilot.Date(column.fromDate);
            day.end = new DayPilot.Date(column.toDate);

            var duration = new DayPilot.Duration(day.start, day.end);

            if (duration.totalDays() <= 2) {
                day.width = 30 * duration.totalDays();
            } else {
                day.width = 90;
            }

            timeline.push(day);
        }
        scale = 'Manual';
    }

    const schedulerRef = useRef();

    const findBiggest = (limits, value) => {

        let max = null;

        for (const key in limits) {

            const compareValueKey = limits?.[key]?.[value];

            if (compareValueKey == null) {
                continue;
            }

            if (max == null || max < compareValueKey) {
                max = compareValueKey;
            }
        }

        return max;
    };

    const [config, setConfig] = useState({
        locale: locale,
        startDate: fromDate,
        days: days,
        scale: scale,
        timeline: timeline,
        timeHeaders: [
            {groupBy: "Month"},
            //{groupBy: "Week"},
            {groupBy: "Cell"}
        ],
        onBeforeTimeHeaderRender: function (args) {
            if (args.header.level === 1) {
                var duration = new DayPilot.Duration(args.header.start, args.header.end);

                if (duration.totalDays() > 1) {
                    args.header.html = args.header.start.getDay() + ' - ' + (args.header.end.getDay() - 1);
                }
            }
        },
        cellWidthSpec: "Fixed",
        cellWidth: 30,
        durationBarVisible: false,
        eventMoveHandling: "Update",
        eventResizeHandling: "Disabled",
        treeEnabled: true,
        rowHeaderColumns: [
            {name: i18n.tr('sio.field.group')},
        ],
        onEventMove: async (args) => {

            args.async = true;

            const modal = await DayPilot.Modal.confirm(i18n.tr('tourism-ship.move'));

            if (modal.canceled) {
                args.preventDefault();
                args.loaded();
                return;
            }

            try {
                await client.post('tourism-ship/cabine/move', {
                    objectModelId: args.e.data.modelId,
                    objectId: args.e.data.id.split('_')?.[0],
                    oldResource: args.e.data.resource,
                    newResource: args.newResource,
                    newStart: args.newStart,
                    newEnd: args.newEnd,
                });

                args.loaded();

                flash.success(i18n.tr('tourism-ship.successfully-moved'));

            } catch (e) {

                flash.error(i18n.tr(e.data.message ?? 'tourism-ship.errors.could-not-move'));
                args.preventDefault();
                args.loaded();
            }
        },
        cellBubble: new DayPilot.Bubble({
            position: "Right",
            onBeforeDomAdd: args => {
                const dp = getScheduler();

                const resource = dp.rows.find(args.source.resource);
                const limits = resource?.data?.limits;

                if (!limits) {
                    return;
                }

                const limit = limits[args.source.start.toString('yyyy-MM-dd')] ?? null;

                if (!limit || !limit.bubbleHtml) {
                    return;
                }

                args.html = limit.bubbleHtml;
            },
        }),
        onBeforeEventRender: args => {
            if (!args.data.backColor) {
                args.data.backColor = "#93c47d";
            }
            args.data.borderColor = "darker";
            args.data.fontColor = "white";

            if (args.data.subContingentColor) {

                console.log('TEST', args.data.subContingentColor);

                args.data.areas = [{
                    html: "",
                    style: "border-top: 15px solid "+args.data.subContingentColor+"; border-left: 15px solid transparent;",
                    top: 0,
                    right: 0,
                    height: 0,
                    width: 0,
                }];
            }
        },
        onEventClicked: (args) => {

            if (args?.e?.data?.link) {
                window.open(args?.e?.data?.link, '_blank');
            }
        },
        onBeforeCellRender: args => {
            const dp = getScheduler();
            const resource = dp.rows.find(args.cell.resource);

            if (args.cell.isParent) {
                const cellHeight = resource.data.eventHeight ?? dp.eventHeight;

                const counterPartValue = compareValue ?? counterPart[showValue];
                const limits = resource?.data?.limits;

                if (limits) {

                    const limit = limits[args.cell.start.toString('yyyy-MM-dd')] ?? null;

                    const currentValue = limit?.[showValue];
                    const maxValue = findBiggest(limits, counterPartValue);

                    args.cell.areas = [];

                    let color;
                    let overbooked = false;

                    if (maxValue > 0 && currentValue != null) {

                        const percentage = (currentValue / maxValue);

                        if (percentage < 0) {
                            overbooked = true;
                        } else if (showValue === 'bookedSingle' || showValue === 'booked' || showValue === 'bookedSubContingent' || showValue === 'blockedSubContingent' || showValue === 'blocked') {
                            color = colors['neutral'];
                        } else if (percentage === 0.0) {
                            color = colors['full'];
                        } else if (percentage < 0.1 || currentValue <= 2) {
                            color = colors['warning'];
                        } else {
                            color = colors['free'];
                        }

                        if (color) {
                            args.cell.areas.push({
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backColor: color[0],
                                height: (percentage * (cellHeight - 30)) - 3,
                                style: "border-top: 2px solid " + color[1],
                            });
                        }
                    }

                    if (currentValue != null) {

                        if (currentValue < 0) {
                            overbooked = true;
                        }

                        let html = '<span>' + currentValue + "</span>";

                        if (currentValue === 0) {
                            html = '<span style="color: #ABABAB;">' + currentValue + "</span>";
                        }

                        if (limit.duplicates > 0) {
                            overbooked = true;
                            html += '<span>&nbsp;<i class="fa fa-clone"></i>x' + limit.duplicates + '</span>';
                        }

                        if (limit.queues > 0) {
                            overbooked = true;
                            html += '<span>&nbsp;<i class="fa fa-layer-group"></i>x' + limit.queues + '</span>';
                        }

                        args.cell.areas.push({
                            bubbleHtml: limit.bubbleHtml,
                            html: html,
                            style: "display: flex; align-items: center; justify-content: center; font-size: 12px; font-weight: bold; border-bottom: 1px solid #EBEBEB",
                            top: 0,
                            height: 30,
                            left: 0,
                            right: 0,
                            backColor: overbooked ? '#ee5151' : null,
                        });
                    }
                }
            } else {

                const notForDates = resource?.data?.notForDates;

                if (notForDates) {

                    const notForDate = notForDates[args.cell.start.toString('yyyy-MM-dd')] ?? null;

                    if (notForDate) {
                        args.cell.properties.disabled = true;
                        args.cell.areas = [
                            { left: 0, right: 0, top: 0, bottom: 0, style: "background-image: linear-gradient(135deg, #ddd 10%, transparent 10%, transparent 50%, #ddd 50%, #ddd 60%, transparent 60%, transparent); background-size: 15px 15px;" }
                        ];
                    }
                }
            }
        }
    });

    const getScheduler = () => schedulerRef.current.control;

    useEffect(() => {
        getScheduler().update({
            resources,
            events
        });
    }, []);

    return (
        <div>
            <DayPilotScheduler
                {...config}
                ref={schedulerRef}
            />
        </div>
    );

};

export default VacancyDisplay;
